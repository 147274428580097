import React from 'react'
import tableStyle from "./table.module.css"

const Table = ({ children, className }) => {
    return (
        <div className={`${tableStyle.tableContainer} ${className}`}>
            <table>{children}</table>
        </div>
    )
}

const HeadController = ({ children }) => {
    return (
        <thead>
            <tr>
                {children}
            </tr>
        </thead>
    )
}

const BodyController = ({ children }) => {
    return (
        <tbody>
            {children}
        </tbody>
    )
}

Table.HeadController = HeadController;
Table.BodyController = BodyController;

export default Table