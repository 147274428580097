import React from 'react'
import style from "./editPriceCard.module.css"

const EditPricesCard = ({ priceCard, edit }) => {
    const { title, iteration, hotels } = priceCard;
    return (
        <>
            <div className={style.priceCard}>
                <h3>{title}</h3>
                <div>
                    <div className={style.pricesSection}>
                        <h4>Prices</h4>
                        <div className={style.iterationsContainer}>
                            {
                                iteration.map((iteration) => (
                                    <IterationsSection iteration={iteration} />
                                ))
                            }
                        </div>
                    </div>
                    <div className={style.hotelsSection}>
                        <h4>Hotels</h4>
                        {
                            hotels.map((hotel) => (
                                <HotelCard hotel={hotel} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditPricesCard


const IterationsSection = ({ iteration }) => {
    const { start, end, details } = iteration
    return (
        <div className={style.iterationsSection}>
            <h5>{start} - {end}</h5>
            <div className={style.detailsContainer}>
                {details.map((detail) => (
                    <IterationDetails detail={detail} />
                ))}
            </div>
        </div>
    )
}


const IterationDetails = ({ detail }) => {
    const { label, price } = detail
    return (
        <div className={style.detailsCard}>
            <p>{label}</p>
            <p>{price}</p>
        </div>
    )
}


const HotelCard = ({ hotel }) => {
    const { image, name, link } = hotel
    return (
        <div className={style.hotelCard}>
            <img src={image} alt="" />
            <a href={link}>{name}</a>
        </div>
    )
}




