import React from 'react'
import "../../dashboardIndex.css"
import destinationStyle from "./destinationCard.module.css"
import { BsPencilFill } from 'react-icons/bs';
import { BsTrashFill } from 'react-icons/bs';

const DestinationCard = ({image, country, content, rating}) => {
    return (
        <div className={destinationStyle.card}>
            <div className={destinationStyle.imageSection} style={{ backgroundImage: `url(${image})` }}>
                <div className={destinationStyle.contentSection}>
                    <div>
                        <h3>{country}</h3>
                        <div className={destinationStyle.ratingStart} title="Rated 5 out of 5">
                            <span style={{ width: rating }}></span>
                        </div>
                        <p className='transitionAnimation'>{content}</p>
                    </div>
                </div>
            </div>
            <div className={destinationStyle.actionSection}>
                <span className='transitionAnimation'><BsPencilFill /> Edit</span>
                <span className='transitionAnimation'><BsTrashFill /> Delete</span>
            </div>
        </div>
    )
}

export default DestinationCard