import React from 'react'
import style from "./editOverviewCard.module.css"

const EditOverviewCard = ({ icon, text, subText, details }) => {
    return (
      <div className={style.cardContainer}>
        <div className={style.icon}>{icon}</div>
        <p>{text}</p>
        <h3>{subText}</h3>
        <p>{details}</p>
      </div>
    )
  }

export default EditOverviewCard