import React, { useState } from 'react';
import wishlistStyles from "./dashboardWishlist.module.css";
import "../../dashboardIndex.css";

import img7 from "../../../images/img7.jpg";
import img8 from "../../../images/gallery-8.jpg";
import img9 from "../../../images/El-Moez-Street.jpg";
import img6 from "../../../images/img6.jpg";
import luxorTrip from "../../../images/Luxor-Trips-in-Egypt.jpg";
import camelTrip from "../../../images/camel-trip.jpg";

import WishlistCard from '../../components/wishlistCard/wishlistCard';
import Pagination from '../../components/pagination/pagination';

const DashboardWishlist = () => {
  const [packages, setPackages] = useState([
    {
      id: 1,
      image: img9,
      price: "$633,00",
      title: `Cairo Day tour`,
      reviews: "25",
      rating: "60%",
    },
    {
      id: 2,
      image: img7,
      price: "$608,00",
      title: `Day tour from Cairo to Luxor (BY Flight)`,
      reviews: "17",
      rating: "80%",
    },
    {
      id: 3,
      image: camelTrip,
      price: "$100,00",
      title: `Day tour from Hurghada to Cairo (BY Flight)`,
      reviews: "22",
      rating: "100%",
    },
    {
      id: 4,
      image: img8,
      price: "$235,00",
      title: `Cairo Day tour`,
      reviews: "22",
      rating: "20%",
    },
    {
      id: 5,
      image: img6,
      price: "$2,000",
      title: `Cairo Day tour`,
      reviews: "30",
      rating: "100%",
    },
    {
      id: 6,
      image: luxorTrip,
      price: "$3,200",
      title: `Day tour from Hurghada to Cairo (BY Vehicle)`,
      reviews: "19",
      rating: "40%",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = packages.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(packages.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <main className='mainContainer'>
      <div className={wishlistStyles.packagesContainer}>
        {currentItems.map((pack) => (
          <WishlistCard 
            key={pack.id} 
            image={pack.image} 
            price={pack.price} 
            rating={pack.rating} 
            review={pack.reviews}>
            {pack.title}
          </WishlistCard>
        ))}
      </div>

      <Pagination 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={handlePageChange} 
      />
    </main>
  );
}

export default DashboardWishlist;
