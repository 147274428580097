import React from 'react'
import { TbMoodEmpty } from "react-icons/tb";
import style from "./emptyNoItems.module.css"

const EmptyNoItems = () => {
    return (
        <div className={style.Empty}>
            <TbMoodEmpty />
            <p>No Details Yet!</p>
        </div>
    )
}

export default EmptyNoItems