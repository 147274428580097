import React, { useState } from 'react'
import "../../dashboardIndex.css"
import commentsStyle from "./dashboardComments.module.css"
import Pagination from './../../components/pagination/pagination';

import boy1 from "../../assets/images/users/boy1.jpg"
import boy2 from "../../assets/images/users/boy2.jpg"
import boy3 from "../../assets/images/users/boy3.jpg"
import boy4 from "../../assets/images/users/boy4.jpg"
import boy5 from "../../assets/images/users/boy5.jpg"

import girl1 from "../../assets/images/users/girl1.jpg"
import girl2 from "../../assets/images/users/girl2.jpg"
import girl3 from "../../assets/images/users/girl3.jpg"
import girl4 from "../../assets/images/users/girl4.jpg"
import girl5 from "../../assets/images/users/girl5.jpg"
import CommentCard from '../../components/commentCard/commentCard';

const DashboardComments = () => {
  const [comments, setComments] = useState([
    {
      id: 1,
      ownerImage: boy1,
      ownerName: "Tom Sawyer",
      date: "10 Jan 2024",
      commentedOn: "Tips To Reduce Your Travel Costs",
      comment: "I had a fantastic time in Japan last month. The cherry blossoms in Kyoto were in full bloom and the food was out of this world. Can't wait to go back!",
      replies: [
        {
          id: 1,
          ownerImage: girl1,
          ownerName: "Sarah Tom",
          date: "10 Jan 2024",
          comment: "Sounds amazing, Sarah! How were the local guides? I've heard they can make or break the experience.",
        },
      ]
    },
    {
      id: 2,
      ownerImage: boy2,
      ownerName: "Ethan Miller",
      date: "10 Jan 2024",
      commentedOn: "Tips To Reduce Your Travel Costs",
      comment: "Just got back from an amazing trip to Egypt! The pyramids were breathtaking and the history is incredible. Highly recommend the camel rides at Giza!",
      replies: [
        {
          id: 1,
          ownerImage: girl2,
          ownerName: "Olivia Parker",
          date: "10 Jan 2024",
          comment: "Glad to hear you enjoyed your trip, Sarah! The camel rides are definitely a unique experience. Did you get a chance to visit the Egyptian Museum too?",
        },
        {
          id: 2,
          ownerImage: girl3,
          ownerName: "Ava King",
          date: "10 Jan 2024",
          comment: "Sounds amazing, Sarah! How were the local guides? I've heard they can make or break the experience.",
        }
      ]
    },
    {
      id: 3,
      ownerImage: girl4,
      ownerName: "Emily Rayan",
      date: "10 Jan 2024",
      commentedOn: "Tips To Reduce Your Travel Costs",
      comment: "Just returned from a road trip across New Zealand. The landscapes are like something out of a movie. Milford Sound was a definite highlight!",
      replies: [
        {
          id: 1,
          ownerImage: boy4,
          ownerName: "Mason Brown",
          date: "10 Jan 2024",
          comment: "New Zealand sounds incredible, Emily! How were the driving conditions? I'm planning a similar trip.",
        },
      ]
    },
    {
      id: 4,
      ownerImage: boy5,
      ownerName: "Michael Boal",
      date: "10 Jan 2024",
      commentedOn: "Tips To Reduce Your Travel Costs",
      comment: "Spent two weeks exploring the beaches of Thailand. The water is so clear and warm, and the snorkeling is unbelievable. Koh Phi Phi was my favorite spot.",
      replies: [
        {
          id: 1,
          ownerImage: girl5,
          ownerName: "Sophia Davis",
          date: "10 Jan 2024",
          comment: "Thailand's beaches are the best! Did you visit any of the islands by boat? I've heard the views are stunning.",
        },
      ]
    },
    {
      id: 5,
      ownerImage: girl2,
      ownerName: "Jessica Loan",
      date: "10 Jan 2024",
      commentedOn: "Tips To Reduce Your Travel Costs",
      comment: "Had an unforgettable adventure in Iceland. The Northern Lights were magical and the Blue Lagoon was the perfect way to relax after hiking the glaciers.",
      replies: [
        {
          id: 1,
          ownerImage: boy3,
          ownerName: "James Wilson",
          date: "10 Jan 2024",
          comment: "Iceland sounds like a dream, Jessica! Were you able to capture any good photos of the Northern Lights?",
        },
        {
          id: 2,
          ownerImage: boy2,
          ownerName: "Liam Smith",
          date: "10 Jan 2024",
          comment: "The Blue Lagoon looks so relaxing! How cold was it while you were there? I'm planning to go next winter.",
        }
      ]
    },
    {
      id: 6,
      ownerImage: girl5,
      ownerName: "Emma Johnson",
      date: "10 Jan 2024",
      commentedOn: "Tips To Reduce Your Travel Costs",
      comment: "This Cairo day tour sounds amazing! I've always wanted to visit the Egyptian Museum and the Pyramids of Giza. How was the tour guide? Were they knowledgeable?",
      replies: [
        {
          id: 1,
          ownerImage: girl1,
          ownerName: "Olivia Thompson",
          date: "10 Jan 2024",
          comment: "The tour guide was fantastic! Very knowledgeable and made the experience even more enjoyable. You'll love it!",
        },
      ]
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = comments.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(comments.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <main className='mainContainer'>
      <div className={commentsStyle.commentsContainer}>
        {currentItems.map((com) => (
          <CommentCard
          ownerImage={com.ownerImage}
          ownerName={com.ownerName}
          date={com.date}
          commentedOn={com.commentedOn}
          comment={com.comment}
          replies={com.replies}   />
        ))}
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </main>
  );
}

export default DashboardComments