import React, { useState } from 'react'
import { useParams } from 'react-router-dom';

import { IoCloseCircle } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { FaSquarePlus } from "react-icons/fa6";

import img1 from "../../../images/Luxor-Trips-in-Egypt.jpg"
import img2 from "../../../images/Saqqara.jpg"
import img3 from "../../../images/slider-banner-2.jpg"
import img4 from "../../../images/karnak.jpeg"
import img5 from "../../../images/img7.jpg"
import img6 from "../../../images/camel-trip.jpg"
import img7 from "../../../images/img13.jpg"
import img8 from "../../../images/img2 - Copy.jpg"
import img9 from "../../../images/gallery-6.jpg"

import style from "./dashboardTourPage.module.css"
import IncludeExclude from '../../components/tourDetailsSections/IncludeExclude/IncludeExclude';
import EditItineraryCard from '../../components/tourDetailsSections/editItineraryCard/editItineraryCard';
import EditTourImages from '../../components/tourDetailsSections/editTourImages/editTourImages';
import Overview from '../../components/tourDetailsSections/Overview/Overview';
import EditPricesCard from '../../components/tourDetailsSections/editPriceCard/editPriceCard';
import Modal from '../../../components/shared/Modal/Modal';
import Form from '../../../components/shared/Form/Form';
import About from './../../../pages/about';
import EmptyNoItems from '../../components/emptyNoItems/emptyNoItems';

const images = [img1, img2, img3, img4, img5, img6];
const itineraries = [
    {
        title: "4 Days - Aswan / Luxor Every Friday",
        days: [
            {
                label: "Day 1: Embark Dahabiya",
                programDetails: {
                    details: ["Embark in Aswan at 12:00 PM", "Visit Philae Temple", "Dinner on board"],
                    summary: "Sailing at 17:00 PM and overnight Herdiab Island."
                },
                meals: "Lunch and Dinner",
                visits: "Aswan, Philae Temple"
            },
            {
                label: "Day 2: Kom Ombo",
                programDetails: {
                    details: ["Breakfast on board and sailing to Kom Ombo", "Visit Kom Ombo Temple", "Sailing to Edfu and overnight"],
                    summary: "Overnight at Edfu."
                },
                meals: "Breakfast, Lunch, and Dinner",
                visits: "Kom Ombo Temple"
            },
            {
                label: "Day 3: Sailing to Edfu",
                programDetails: {
                    details: ["Enjoy early breakfast on board while sailing to Edfu", "Visit Edfu Temple", "Sailing to Esna and crossing Esna Lock while having Lunch"],
                    summary: "Overnight at Luxor."
                },
                meals: "Breakfast, Lunch, and Dinner",
                visits: "Kom Ombo Temple"
            },
            {
                label: "Day 4: Luxor - Final Departure",
                programDetails: {
                    details: ["Breakfast on board", "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple", "Transfer to Luxor Airport for your final departure"],
                    summary: ""
                },
                meals: "Breakfast",
                visits: "Valley of the Kings, Karnak Temple"
            },
        ],
        NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits"
    },
    {
        title: "5 Days - Luxor / Aswan Every Monday",
        days: [
            {
                label: "Day 1: Embark Dahabiya",
                programDetails: {
                    details: ["Embark in Aswan at 12:00 PM", "Visit Philae Temple", "Dinner on board"],
                    summary: "Sailing at 17:00 PM and overnight Herdiab Island."
                },
                meals: "Lunch and Dinner",
                visits: "Aswan, Philae Temple"
            },
            {
                label: "Day 2: Kom Ombo",
                programDetails: {
                    details: ["Breakfast on board and sailing to Kom Ombo", "Visit Kom Ombo Temple", "Sailing to Edfu and overnight"],
                    summary: "Overnight at Edfu."
                },
                meals: "Breakfast, Lunch, and Dinner",
                visits: "Kom Ombo Temple"
            },
            {
                label: "Day 3: Sailing to Edfu",
                programDetails: {
                    details: ["Enjoy early breakfast on board while sailing to Edfu", "Visit Edfu Temple", "Sailing to Esna and crossing Esna Lock while having Lunch"],
                    summary: "Overnight at Luxor."
                },
                meals: "Breakfast, Lunch, and Dinner",
                visits: "Kom Ombo Temple"
            },
            {
                label: "Day 4: Luxor - Final Departure",
                programDetails: {
                    details: ["Breakfast on board", "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple", "Transfer to Luxor Airport for your final departure"],
                    summary: ""
                },
                meals: "Breakfast",
                visits: "Valley of the Kings, Karnak Temple"
            },
        ],
        NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits"
    },
    {
        title: "8 Days - Luxor / Luxor Every Monday",
        days: [
            {
                label: "Day 1: Embark Dahabiya",
                programDetails: {
                    details: ["Embark in Aswan at 12:00 PM", "Visit Philae Temple", "Dinner on board"],
                    summary: "Sailing at 17:00 PM and overnight Herdiab Island."
                },
                meals: "Lunch and Dinner",
                visits: "Aswan, Philae Temple"
            },
            {
                label: "Day 2: Kom Ombo",
                programDetails: {
                    details: ["Breakfast on board and sailing to Kom Ombo", "Visit Kom Ombo Temple", "Sailing to Edfu and overnight"],
                    summary: "Overnight at Edfu."
                },
                meals: "Breakfast, Lunch, and Dinner",
                visits: "Kom Ombo Temple"
            },
            {
                label: "Day 3: Sailing to Edfu",
                programDetails: {
                    details: ["Enjoy early breakfast on board while sailing to Edfu", "Visit Edfu Temple", "Sailing to Esna and crossing Esna Lock while having Lunch"],
                    summary: "Overnight at Luxor."
                },
                meals: "Breakfast, Lunch, and Dinner",
                visits: "Kom Ombo Temple"
            },
            {
                label: "Day 4: Luxor - Final Departure",
                programDetails: {
                    details: ["Breakfast on board", "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple", "Transfer to Luxor Airport for your final departure"],
                    summary: ""
                },
                meals: "Breakfast",
                visits: "Valley of the Kings, Karnak Temple"
            },
        ],
        NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits"
    },
]
const prices = [
    {
        title: "4-Day Itinerary",
        iteration: [
            {
                start: "may",
                end: "august",
                details: [
                    {
                        label: "Per Person in Double Room",
                        price: "US$ 2674"
                    },
                    {
                        label: "Per Person in Single Room",
                        price: "US$ 4134"
                    }
                ]
            },
            {
                start: "september",
                end: "april",
                details: [
                    {
                        label: "Per Person in Double Room",
                        price: "US$ 3447"
                    },
                    {
                        label: "Per Person in Single Room",
                        price: "US$ 5267"
                    }
                ]
            },
        ],
        hotels: [
            {
                image: img7,
                name: "Asharanda Nebyt Dahabiya Cruise",
                link: "https://www.tripadvisor.com/Attraction_Review-g294201-d472084-Reviews-Nile_River-Cairo_Cairo_Governorate.html",
                rating: '100%'
            }
        ]

    },
    {
        title: "5-Day Itinerary",
        iteration: [
            {
                start: "may",
                end: "august",
                details: [
                    {
                        label: "Per Person in Double Room",
                        price: "US$ 3542"
                    },
                    {
                        label: "Per Person in Single Room",
                        price: "US$ 5486"
                    }
                ]
            },
            {
                start: "september",
                end: "april",
                details: [
                    {
                        label: "Per Person in Double Room",
                        price: "US$ 3650"
                    },
                    {
                        label: "Per Person in Single Room",
                        price: "US$ 5594"
                    }
                ]
            },
        ],
        hotels: [
            {
                image: img8,
                name: "Asharanda Nebyt Dahabiya Cruise",
                link: "https://www.tripadvisor.com/Attraction_Review-g294201-d472084-Reviews-Nile_River-Cairo_Cairo_Governorate.html",
                rating: "40%"
            }
        ]

    },
    {
        title: "8-Day Itinerary",
        iteration: [
            {
                start: "may",
                end: "august",
                details: [
                    {
                        label: "Per Person in Double Cabin",
                        price: "US$ 6135"
                    },
                    {
                        label: "Per Person in Double Suite",
                        price: "US$ 8970"
                    },
                    {
                        label: "Per Person in Single Cabin",
                        price: "US$ 9535"
                    }
                ]
            },
            {
                start: "september",
                end: "april",
                details: [
                    {
                        label: "Per Person in Double Cabin",
                        price: "US$ 7905"
                    },
                    {
                        label: "Per Person in Double Suite",
                        price: "US$ 11450"
                    },
                    {
                        label: "Per Person in Single Cabin",
                        price: "US$ 12155"
                    }
                ]
            },
        ],
        hotels: [
            {
                image: img9,
                name: "Asharanda Nebyt Dahabiya Cruise",
                link: "https://www.tripadvisor.com/Attraction_Review-g294201-d472084-Reviews-Nile_River-Cairo_Cairo_Governorate.html",
                rating: "60%"
            }
        ]

    },
]
const cruise = {
    description: "All the rooms and suites at Dahabiya Nile Cruise in egypt have been designed and styled to make you feel comfortable every moment you’re there.",
    NumOfRooms: ["6 cabins with twin or large beds", "1 suit with a private terrace"]
}

const cabin = [
    "In-room private safe",
    "Large windows with a panoramic view",
    "Fluffy and modern duvets",
    "Hairdryer",
    "Elegant fine dining room and lounge",
    "Electronic fire and smoke detection",
    "Wireless internet capabilities",
    "Chaise-longue chairs on the sun deck",
    "Soft Drinks in the minibar",
    "Free Wifi in The room"
]

const DashboardTourPage = () => {
    const { tourId } = useParams();

    const [tab, setTab] = useState(itineraries[0].title)
    const [currentItineraries, setCurrentItineraries] = useState(itineraries[0])
    const [editTitle, setEditTitle] = useState(false);
    const [editOverview, setEditOverview] = useState(false);
    const [editIncludeExclude, setEditIncludeExclude] = useState(false);
    const [editItinerary, setEditItinerary] = useState(false);
    const [addItinerary, setAddItinerary] = useState(false);
    const [editCruise, setEditCruise] = useState(false);
    const [editCabin, setEditCabin] = useState(false);
    const [editPrice, setEditPrice] = useState(false);
    const [addPrice, setAddPrice] = useState(false);



    return (
        <>
            <main className='mainContainer'>
                <div className={style.container}>
                    <h1>{editTitle ? <input type='text' placeholder='Enter tour name' /> : "Day tour from Cairo to Luxor (BY Flight)"}<span className={style.editContainer}>{editTitle ? <><FaCheck title='Save Changes' /><IoCloseCircle title='Cancel Changes' onClick={() => setEditTitle(!editTitle)} /></> : <MdEdit title='Edit tour title' onClick={() => setEditTitle(!editTitle)} />}</span></h1>
                    <div className={style.details}>
                        <div className={style.leftSection}>
                            <div className={style.images}>
                                <EditTourImages images={images} />
                            </div>
                            <div className={style.overview}>
                                <h2>Overview <span className={style.editContainer}>{editOverview ? <IoCloseCircle title='Cancel Changes' onClick={() => setEditOverview(!editOverview)} /> : <button className={`button-primary ${style.edit}`} type='button' onClick={() => setEditOverview(!editOverview)}><MdEdit />Edit</button>}</span></h2>
                                <Overview edit={editOverview} />
                            </div>
                        </div>
                    </div>
                    <>
                        <div className={style.editButton}><span className={style.editContainer}>{editIncludeExclude ? <IoCloseCircle title='Cancel Changes' onClick={() => setEditIncludeExclude(!editIncludeExclude)} /> : <button className={`button-primary ${style.edit}`} type='button' onClick={() => setEditIncludeExclude(!editIncludeExclude)}><MdEdit />Edit</button>}</span></div>
                        <IncludeExclude edit={editIncludeExclude} />
                    </>
                    <div className={style.itinerary}>
                        <h2>Itinerary <span className={style.editContainer}>{editItinerary ? <IoCloseCircle title='Cancel Changes' onClick={() => setEditItinerary(!editItinerary)} /> : <button className={`button-primary ${style.edit}`} type='button' onClick={() => setEditItinerary(!editItinerary)}><MdEdit />Edit</button>}</span></h2>
                        <div className={style.tabs}>
                            <ul>
                                {itineraries.map((itinerary, index) => (
                                    <li
                                        key={index}
                                        onClick={() => { setTab(itinerary.title); setCurrentItineraries(itinerary) }}
                                        className={(tab === itinerary.title) && style.active}
                                    >
                                        {itinerary.title} <IoClose />
                                    </li>
                                ))}
                                <div onClick={() => setAddItinerary(true)}><FaPlus /></div>
                            </ul>
                        </div>
                        <div className={style.page}>
                            <EditItineraryCard edit={editItinerary} itinerary={currentItineraries} />
                        </div>
                    </div>
                    <div className={style.cruise}>
                        <h2>Cruise <span className={style.editContainer}><button className={`button-primary ${style.edit}`} type='button' onClick={() => setEditCruise(!editCruise)}><MdEdit />Edit</button></span></h2>
                        <p>{cruise.description}</p>
                        <h4>Number of Rooms</h4>
                        <ul>
                            {
                                (cruise.NumOfRooms).map((item) => <li>{item}</li>)
                            }
                        </ul>
                    </div>
                    <div className={style.cabin}>
                        <h2>Cabin <span className={style.editContainer}><button className={`button-primary ${style.edit}`} type='button' onClick={() => setEditCabin(!editCabin)}><MdEdit />Edit</button></span></h2>
                        <ul>
                            {
                                cabin.map((item) => <li>{item}</li>)
                            }
                        </ul>
                    </div>
                    <div className={style.prices}>
                        <h2>Prices <span className={style.editContainer}>{editPrice ? <><button className={`button-primary ${style.edit}`} type='button' onClick={() => setAddPrice(!addPrice)}>Add Card</button><IoCloseCircle title='Cancel Changes' onClick={() => setEditPrice(!editPrice)} /></> : <button className={`button-primary ${style.edit}`} type='button' onClick={() => setEditPrice(!editPrice)}><MdEdit />Edit</button>}</span></h2>
                        <div className={style.PricesCardsContainer}>
                            {
                                prices.map((priceCard) => <EditPricesCard edit={editPrice} priceCard={priceCard} />)
                            }
                        </div>
                    </div>

                </div>
            </main >
            {addItinerary &&
                <Modal isAppear onClose={() => setAddItinerary(false)}>
                    <AddItinerary />
                </Modal>
            }
            {editCruise &&
                <Modal isAppear onClose={() => setEditCruise(false)}>
                    <EditCruise cruise={cruise} />
                </Modal>
            }
            {editCabin &&
                <Modal isAppear onClose={() => setEditCabin(false)}>
                    <EditCabin cabin={cabin} />
                </Modal>
            }
            {addPrice &&
                <Modal isAppear onClose={() => setAddPrice(false)}>
                    <AddPriceCard card={prices} />
                </Modal>
            }

        </>
    )
}

export default DashboardTourPage



const AddItinerary = () => {
    return (
        <Form title="Add New Itinerary" className={style.addItineraryForm}>
            <Form.TextController placeholder="Enter Itinerary Title" />

            <h2>Itinerary Position</h2>
            <Form.TextController placeholder="Enter Itinerary lat" />
            <Form.TextController placeholder="Enter Itinerary lng" />
            <Form.ButtonController type='submit'>Add</Form.ButtonController>
        </Form>
    )
}


const AddPriceCard = () => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const [title, setTitle] = useState('');

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    const [label, setLabel] = useState('');
    const [price, setPrice] = useState('');

    const [cards, setCards] = useState([]);
    const [items, setItems] = useState([]);

    const handleDeleteCard = (indexToDelete) => {

    };

    const handleDeleteItem = (indexToDelete) => {
        const updatedList = items.filter((_, index) => index !== indexToDelete);
        setItems(updatedList);
    };

    const handleAddItem = () => {
        if (label.trim() && price.trim()) {
            setItems([...items, { label, price }]);
            setLabel('');
            setPrice('');
        }
    };

    const handleAddCard = () => {
        if (start.trim() && end.trim()) {
            setCards([...cards, { start, end, items }]);
            // setStart('');
            // setEnd('');
        }
    };


    return (
        <Form title="Add New Tour Prices" className={style.addPricesForm}>
            <Form.TextController placeholder="Card Title" value={title} onChange={(e) => setTitle(e.target.value)} />
            <h2>Prices</h2>
            <div className={style.pricesContainer}>
                <div className={style.tourDuration}>
                    <Form.SelectController placeholder="Starting" options={months} value={start} onChange={(e) => setStart(e.target.value)} />
                    <p>-</p>
                    <Form.SelectController placeholder="Ending" options={months} value={end} onChange={(e) => setEnd(e.target.value)} />
                </div>
                <div className={style.pricesDetails}>
                    {items.length ?
                        <ul>{items.map((item, index) => (
                            <li key={index}>
                                <p>{item.label}</p>
                                <p>{item.price}</p>
                                <IoCloseCircle onClick={() => handleDeleteItem(index)} />
                            </li>
                        ))}
                        </ul>
                        :
                        <EmptyNoItems />
                    }
                    <div>
                        <input
                            type='text'
                            placeholder="Label"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                        />
                        <input
                            type='text'
                            placeholder="Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                        <button
                            type='button'
                            className='button-primary'
                            onClick={handleAddItem}
                        >
                            Add Item
                        </button>
                    </div>

                </div>
                <button
                    type='button'
                    className='button-primary'
                    onClick={handleAddCard}
                    style={{ width: "100%", marginTop: "20px" }}
                >
                    Add
                </button>
            </div>
            {cards.map((i)=><p>i</p>)}
            <Form.ButtonController type='submit'>Add</Form.ButtonController>
        </Form>
    )
}


const EditCruise = ({ cruise }) => {
    const [newCruise, setNewCruise] = useState(cruise ? { description: cruise.description, NumOfRooms: cruise.NumOfRooms } : { description: '', NumOfRooms: [] });

    return (
        <Form className={style.addCruiseForm}>
            <Form.TextareaController placeholder="Enter cruise description" value={newCruise.description} onChange={(e) => setNewCruise({ ...newCruise, description: e.target.value })} />
            <h2>Number of Rooms</h2>
            <Form.AddListController placeholder="Add rooms details" value={newCruise.NumOfRooms} onChange={(updatedCruise) => setNewCruise({ ...newCruise, NumOfRooms: updatedCruise })} />
            <Form.ButtonController type='submit'>Save</Form.ButtonController>
        </Form>
    )
}

const EditCabin = ({ cabin }) => {
    const [newCabin, setNewCabin] = useState(cabin ? cabin : []);

    return (
        <Form className={style.addCabinForm}>
            <Form.AddListController placeholder="Add Cabin" value={newCabin} onChange={(updatedCabin) => setNewCabin(updatedCabin)} />
            <Form.ButtonController type='submit'>Save</Form.ButtonController>
        </Form>
    )
}


