import React, { useState } from 'react'
import PageHeader from './../../components/pageHeader/pageHeader';
import CustomButton from '../../components/customButton/customButton';
import { FaChevronLeft } from 'react-icons/fa6';
import Table from './../../components/customTable/table';

import girl1 from "../../assets/images/users/girl5.jpg";
import girl2 from "../../assets/images/users/girl6.jpg";
import boy1 from "../../assets/images/users/boy5.jpg";
import boy2 from "../../assets/images/users/boy4.jpg";
import boy3 from "../../assets/images/users/boy3.jpg";

import bookingStyle from "./dashboardBookingAndEnquiry.module.css"
import "../../dashboardIndex.css"
import { BsPencilSquare } from 'react-icons/bs';
import { BsTrashFill } from 'react-icons/bs';

const DashboardBookingAndEnquiry = () => {
  const status = {
    APPROVE: "approve",
    PENDING: "pending",
    REJECT: "reject"
  }
  const [users, setUsers] = useState([
    {
      userImage: girl1,
      userName: "Emma Johnson",
      date: "12may",
      destination: "Japan",
      id: 1,
      status: status.APPROVE,
      enquiry: 15,
      people: 9,
    },
    {
      userImage: boy1,
      userName: "Leo Johnson",
      date: "12may",
      destination: "Japan",
      id: 2,
      status: status.PENDING,
      enquiry: 15,
      people: 9,
    },
    {
      userImage: boy2,
      userName: "Liam Thompson",
      date: "12may",
      destination: "Japan",
      id: 3,
      status: status.REJECT,
      enquiry: 15,
      people: 9,
    },
    {
      userImage: girl2,
      userName: "Sophia Davis",
      date: "12may",
      destination: "Japan",
      id: 4,
      status: status.APPROVE,
      enquiry: 15,
      people: 9,
    },
    {
      userImage: boy3,
      userName: "Luck Wilson",
      date: "12may",
      destination: "Japan",
      id: 5,
      status: status.PENDING,
      enquiry: 15,
      people: 9,
    },
  ]);

  return (
    <main className={bookingStyle.mainContainer}>
      <PageHeader description="Airport Hotels The Right Way To Start A Short Break Holiday">Recent Booking</PageHeader>
      <div className={bookingStyle.buttons}> {/* Updated to use styles */}
        <CustomButton>Add New</CustomButton>
        <CustomButton icon={<FaChevronLeft />}>Filter</CustomButton>
      </div>
      <Table className={bookingStyle.usersTable}>
        <Table.HeadController>
          <th>User</th>
          <th>Name</th>
          <th>Date</th>
          <th>Destination</th>
          <th>ID</th>
          <th>Status</th>
          <th>Enquiry</th>
          <th>People</th>
          <th></th>
          <th>Edit</th>
          <th>Delete</th>
        </Table.HeadController>
        <Table.BodyController>
          {users.map(user => (
            <tr>
              <td className={bookingStyle.img}>
                <span style={{ backgroundImage: `url(${user.userImage})` }}></span>
              </td>
              <td>{user.userName}</td>
              <td>{user.date}</td>
              <td>{user.destination}</td>
              <td>{user.id}</td>
              <td><span className={`${user.status === status.APPROVE ? bookingStyle.approve :
                user.status === status.PENDING ? bookingStyle.pending :
                bookingStyle.reject}`}>{user.status}</span></td>
              <td>{user.enquiry}</td>
              <td><span>{user.people}</span></td>
              <td></td>
              <td className="transitionAnimation"><BsPencilSquare /></td>
              <td className="transitionAnimation"><BsTrashFill /></td>
            </tr>
          ))}
        </Table.BodyController>
      </Table>
    </main>
  )
}

export default DashboardBookingAndEnquiry