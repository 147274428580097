import React from "react";
import Header from "../components/header";
import PackageSection from "../components/ToursComponents/PackageSection";
import ActivitySection from "../components/ActivityComponents/ActivitySection";
import Footer from "../components/footer";
import IneerBanner from "../components/IneerBanner";
import { Outlet } from 'react-router-dom';



const Tours = () => {
  return (
    <>
      <main id="content" class="site-main">
        <IneerBanner bannerName={"Tour Packages"} />
        <PackageSection />
        <ActivitySection />
        <Outlet />
      </main>
    </>
  );
};

export default Tours;
