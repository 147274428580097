import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Pagination from './../../components/pagination/pagination';
import "../../dashboardIndex.css";
import toursStyle from "./dashboardTours.module.css"

import img7 from "../../../images/img7.jpg";
import img8 from "../../../images/gallery-8.jpg";
import img9 from "../../../images/El-Moez-Street.jpg";
import img6 from "../../../images/img6.jpg";
import img14 from "../../../images/img14.jpg";
import karnak from "../../../images/karnak.jpeg";
import luxorTrip from "../../../images/Luxor-Trips-in-Egypt.jpg";
import camelTrip from "../../../images/camel-trip.jpg";
import Saqqara from "../../../images/Saqqara.jpg";
import img12 from "../../../images/khan.jpg";

import ToursCard from '../../components/toursCard/toursCard';

const DashboardTours = () => {
  const [packages, setPackages] = useState([
    {
      id: 1,
      image: img9,
      price: "$1,900",
      duration: "7D/6N",
      people: "5",
      location: "Egypt",
      title: "Cairo Day tour",
      StartingPoint: "Cairo or Giza Hotel",
      EndingPoint: "Cairo or Giza Hotel",
      reviews: "25",
      rating: "60%",
      description: `
      Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:
1- Visit Egyptrian museum
2- Visit El Moez Street
3- Visit khan khalili

Meal : lunch 
(Duration 4-5Hours)
`,
      day: 7, night: 6
    },
    {
      id: 2,
      image: img7,
      price: "$1,230",
      duration: "5D/4N",
      people: "8",
      location: "Egypt",
      title: "Day tour from Cairo  to Luxor (BY Flight )",
      StartingPoint: "Cairo  Hotel",
      EndingPoint: "Cairo Hotel",
      reviews: "17",
      rating: "100%",
      description: `Pickup from Cairo Hotel to the Airport take Domestic Flight to Luxor 

Visit Karnak Temples  
Visit Luxor Temples ( from out side)
Visit valley of the kings 
Visit Colossi of Memnon

Meal : Lunch 

Then transfer back to Luxor Airport Fly back to Cairo.

(Duration  about 10 Hours)
`,
      day: 7, night: 6
    },
    {
      id: 3,
      image: camelTrip,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: "Day tour from Hurghada to Cairo(BY Flight )",
      StartingPoint: "Hurghada Hotel",
      EndingPoint: "Hurghada Hotel",
      reviews: "20",
      rating: "100%",
      description: `Pickup from Hurghada Hotel to the Airport take Domestic Flight to Cairo 

Visit the Egyptian Museum 
Visit the Pyramids of Giza
Meals: Lunch 

(Duration 9 Hours)

After finishing the Tour.
 Then transfer back to Cairo  Airport Fly back to Hurghada.
`,
      day: 7, night: 6
    },
    {
      id: 4,
      image: img8,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: `Egypt`,
      title: "Cairo Day tour",
      StartingPoint: "Cairo or Giza Hotel",
      EndingPoint: "Cairo or Giza Hotel",
      reviews: "15",
      rating: "100%",
      description: `Day tour to the Pyramids 
Pickup From hotel 
Visit Giza Pyramids and Sphinx.
Visit Egyptian Museum
Visit old Cairo 

Meal : Lunch

Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

 (Duration 8 Hours)
`,
      day: 7, night: 6
    },
    {
      id: 5,
      image: img6,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: "Cairo Day tour",
      StartingPoint: "Cairo or Giza Hotel",
      EndingPoint: "Cairo or Giza Hotel",
      reviews: "18",
      rating: "100%",
      description: `Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

Visit El Moez Street
Visit Bayt Al-Sihaymi
Visit Al Darb AL Ahmar
Visit Bab Al-Futuh
Visit Bab Zuweila
Visit Bab El Nasr
Visit Mosque – Madrasa of Sultan Barquq
Visit Qalawun Complex
Visit Al-Aqmar Mosque
Visit Aqsunqur Mosque (Blue Mosque)
Visit El Darb EL ASFAR
Visit Souq El-Selah Street

Meal :Lunch 

 (Duration 4-5Hours)
`,
      day: 7, night: 6
    },
    {
      id: 6,
      image: luxorTrip,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: "Day tour from Hurghada to Cairo (BY Vehicle)",
      StartingPoint: "Hurghada Hotel",
      EndingPoint: "Hurghada Hotel",
      reviews: "32",
      rating: "100%",
      description: `Early Morning take the private A/C from your Hotel and Drive to Cairo (500km)
When reach Cairo start your day Tour:

Visit the Egyptian Museum 
Visit the Pyramids of Giza
Meals: Lunch 

(Duration 18 Hours)

After finishing the Tour.
 Transfer back to the Hotel.

Option tours:
●Felucca ride on the Nile 
●Entrance to inside the pyramid
●Camel ride near to the pyramids of Giza
`,
      day: 7, night: 6
    },
    {
      id: 7,
      image: Saqqara,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: "Cairo Day tour",
      StartingPoint: "Cairo or Giza Hotel",
      EndingPoint: "Cairo or Giza Hotel",
      reviews: "40",
      rating: "100%",
      description: `Day tour to the Pyramids 
Pickup From hotel 
Visit Giza Pyramids and Sphinx.
Visit Saqqara Step Pyramid and The Necropolis.
Visit Memphis open air Museum.

Meal : Lunch 

Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

 (Duration 8 Hours)`,
      day: 7, night: 6
    },
    {
      id: 8,
      image: img12,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: "Cairo Day tour",
      StartingPoint: "Cairo or Giza Hotel",
      EndingPoint: "Cairo or Giza Hotel",
      reviews: "28",
      rating: "100%",
      description: `Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

Visit Egyptian museum 
Visit Citadel of Saladin 
Visit khan khalili

Meal : lunch 

 (Duration 7 Hours)
`,
      day: 7, night: 6
    },
    {
      id: 9,
      image: img14,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: "Cairo Day tour",
      StartingPoint: "Cairo or Giza Hotel",
      EndingPoint: "Cairo or Giza Hotel",
      reviews: "34",
      rating: "100%",
      description: `Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

Visit The Grand Egyptian Museum.
Visit  the pyramids of Giza

Meal : Lunch

After finishing the Tour proceed to a Local Restaurant to enjoy your Lunch Meal.
 Transfer back to the Hotel.

`,
      day: 7, night: 6
    },
    {
      id: 10,
      image: karnak,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title:"Day tour from Hurghada to Cairo (BY Vehicle)",
      StartingPoint: "Hurghada Hotel",
      EndingPoint: "Hurghada Hotel",
      reviews: "25",
      rating: "100%",
      description: `Early Morning take the private A/C from your Hotel and Drive to Luxor (310km)
Visit Karnak Temples  
Visit Luxor Temples ( from outside)
Visit valley of the kings 
Visit Colossi of Memnon

Meal : Lunch 

Then transfer back to Hurghada Hotel

(Duration  about 14 Hours)

`,
      day: 7, night: 6
    },

  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = packages.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(packages.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <main className='mainContainer'>
      <div className={toursStyle.packagesContainer}>
        {currentItems.map((pack) => (
          <ToursCard
            key={pack.id}
            id={pack.id}
            image={pack.image}
            price={pack.price}
            starting={pack.StartingPoint}
            ending={pack.EndingPoint}
            rating={pack.rating}
            review={pack.reviews}
            people={pack.people}
            day={pack.day}
            night={pack.night}
            location={pack.location}
            description={pack.description}>
            {pack.title}
          </ToursCard>
        ))}
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <Outlet />
    </main>
  );
}

export default DashboardTours