import React from "react";
import { Link } from "react-router-dom";

import logo6 from "../images/logo6.png";
import logo2 from "../images/logo2.png";
import albumTravelLogo from "../images/Album-Travel-Logo.png";
import SocialMadia from "./socialMadia";
import { ADDRESS, INFO_MAILE, PHONE1, PHONE2 } from "../globals";

const Footer = ({isLoggedIn, userType}) => {
  return (
    <footer id="colophon" className={`site-footer footer-primary ${isLoggedIn === "true" && userType === "admin" ? "force-display-none" : ""}`}>
      <div className="top-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <aside className="widget widget_text">
                <h3 className="widget-title">About Travel</h3>
                <div className="textwidget widget-text">
                  We are committed to curate and flawlessly execute travel
                  experiences that allow travelers to explore the world with
                  ease and create memories that last a lifetime.
                </div>
                <div className="award-img">
                  <a href="#">
                    <img src={logo6} alt="" />
                  </a>
                  <a href="#">
                    <img src={logo2} alt="" />
                  </a>
                </div>
              </aside>
            </div>
            <div className="col-lg-3 col-md-6">
              <aside className="widget widget_text">
                <h3 className="widget-title">CONTACT INFORMATION</h3>
                <div className="textwidget widget-text">
                  PARTNER'S AND CLIENTS .
                  <ul>
                    <li>
                      <a href="#" className="flex">
                        <i className="fas fa-phone-alt"></i>
                        <span>
                          {PHONE1}, <br />
                          {PHONE2}
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-envelope"></i>
                        {INFO_MAILE}
                      </a>
                    </li>
                    <li className="flex">
                      <i className="fas fa-map-marker-alt"></i>
                      {ADDRESS}
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div className="col-lg-3 col-md-6">
              <aside className="widget widget_recent_post">
                <h3 className="widget-title">Latest Post</h3>
                <ul>
                  <li>
                    <h5>
                      <a href="#">
                        We believe that travel is more than just a getaway—it’s
                        a gateway to a richer
                      </a>
                    </h5>
                    <div className="entry-meta">
                      <span className="post-on">
                        <a href="#">May 23, 2024 </a>
                      </span>
                      <span className="comments-link">
                        <a href="#">No Comments</a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <h5>
                      <a href="#">
                        The Wonders of Egypt Egypt, the land of ancient pharaohs
                        and timeless treasures, awaits you! Dive into the rich
                        history
                      </a>
                    </h5>
                    <div className="entry-meta">
                      <span className="post-on">
                        <a href="#">May 23, 2024 </a>
                      </span>
                      <span className="comments-link">
                        <a href="#">No Comments</a>
                      </span>
                    </div>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-lg-3 col-md-6">
              <aside className="widget widget_newslatter">
                <h3 className="widget-title">CONTACT US</h3>
                <div className="widget-text">
                  LET'S JOIN US FOR MORE UPDATE !!
                </div>
                <form className="newslatter-form">
                  <input type="tel" name="s" placeholder="Your Phone.." />
                  <input type="submit" name="s" value="Contact us" />
                </form>
              </aside>
            </div>
          </div>
        </div>
      </div>
      <div className="buttom-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="footer-menu">
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Term &amp; Condition</Link>
                  </li>
                  {/* <li>
                                 <a href="#">FAQ</a>
                              </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-2 text-center">
              <div className="footer-logo">
                <a href="#">
                  <img src={albumTravelLogo} alt="" />
                </a>
              </div>

              <SocialMadia />
            </div>
            <div className="col-md-5">
              <div className="copy-right text-right">
                Copyright © 2024 7P. All rights reserveds
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
