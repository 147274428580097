import React from 'react'
import { Link } from 'react-router-dom';
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "../constants/base_url";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import bg from "../images/bg.jpg";
import logo from "../images/logo.png";
import AuthForm from '../components/AuthForm/AuthForm';
import { signupSchema } from '../Validations/auth/signup.schema';


const Signup = () => {
  const schema = signupSchema();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });

  const { mutate, isError, error, isLoading } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}auth/register`, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: (data) => {
      console.log("Registration successful", data);
    },
    onError: (error) => {
      console.error("Registration failed:", error);
    }
  });

  const onSubmit = (data) => {
    delete data.confirmPassword;
    data.role = 'user';
    console.log("Form Data:", data);
    mutate(data);
  };

  return (
    <div className="login-page" style={{ backgroundImage: `url(${bg})` }}>
      <div className="login-from-wrap">
        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <h1 className="site-title">
            <Link to="#">
              <img src={logo} alt="logo" />
            </Link>
          </h1>

          <AuthForm.TextController
            id="username"
            label="Username"
            errors={errors}
            register={register}
            name='name'
          />

          <AuthForm.TextController
            id="email"
            label="Email"
            errors={errors}
            register={register}
            name='email'
          />

          <AuthForm.TextController
            id="phone"
            label="Phone"
            errors={errors}
            register={register}
            name='phone'
          />

          <AuthForm.TextController
            id="password"
            label="Password"
            type='password'
            errors={errors}
            register={register}
            name='password'
          />

          <AuthForm.TextController
            id="c-password"
            label="Confirm Password"
            type='password'
            errors={errors}
            register={register}
            name='confirmPassword'
          />

          <AuthForm.ButtonController isLoading={isLoading} type='submit'>Register</AuthForm.ButtonController>

          <p className="swap-login-signup">
            Already have an account? <Link to="/login">Login</Link>.
          </p>
        </AuthForm>
      </div>
    </div>
  )
}

export default Signup