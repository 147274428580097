import React from 'react'
import Form from '../../../../components/shared/Form/Form';
import { IoHourglassOutline } from "react-icons/io5";
import { IoEarth } from "react-icons/io5";
import { IoPeople } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";
import style from "./Overview.module.css"
import EditOverviewCard from '../editOverviewCard/editOverviewCard';

const Overview = ({ edit }) => {
    return (edit ?
        <div className={style.editOverview}>
            <Form>
                <div className={style.cardsContainer}>
                    <div>
                        <p>Schedual</p>
                        <Form.TextController
                            placeholder="Schedual Days"
                        />
                        <Form.TextController
                            placeholder="Schedual Nights"
                        />
                    </div>
                    <div>
                        <p>Countries</p>
                        <Form.TextController
                            placeholder="Number of countyies"
                        />
                        <Form.TextController
                            placeholder="Number of cities"
                        />
                    </div>
                    <div>
                        <p>Type</p>
                        <Form.TextController
                            placeholder="Group type"
                        />
                        <Form.TextController
                            placeholder="Number of persons"
                        />
                    </div>
                    <div>
                        <p>Run</p>
                        <Form.TextController
                            placeholder="Tour run from"
                        />
                        <Form.TextController
                            placeholder="Tour run to"
                        />
                    </div>
                </div>
                <Form.TextareaController placeholder="Overview description" />
                <Form.ButtonController type='submit'>Save Changes</Form.ButtonController>
            </Form>
        </div>
        :
        <>
            <div className={style.cardsContainer}>
                <EditOverviewCard icon={<IoHourglassOutline />} text="Schedule" subText="4/5/8 Days" details="3/4/7 Nights" />
                <EditOverviewCard icon={<IoEarth />} text="Countries" subText="1 Country" details="5 Cities" />
                <EditOverviewCard icon={<IoPeople />} text="Type" subText="Small group tour" details="12 Persons" />
                <EditOverviewCard icon={<IoCalendarOutline />} text="Run" subText="Monday from Luxor" details="Friday from Aswan" />
            </div>
            <p>
                Cruise the Nile in style on the Golden Lady of the Nile, Nebyt Dahabiya. The Nebyt, one of Ashranda's most luxuorious Dahabiyas, will offer you elegantly decorated cabins, a shaded sun deck level with a hot tub and chaise lounges. Nebyt Dahabiya will take you between the historic cities of Luxor and Aswan. You will see unique sites, not available on any cruise, such as sailing to El Batekh Island, visiting El Kab Temple and and El Kab village and much more. You will discover the Nile in a very special and unique way while enjoying indescribable calmness and serenity.
            </p>
        </>
    )
}


export default Overview

