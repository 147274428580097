import React, { useState } from 'react';
import PageHeader from './../../components/pageHeader/pageHeader';
import userStyle from "./dashboardUsers.module.css";
import "../../dashboardIndex.css";
import { FaChevronLeft } from "react-icons/fa6";

import girl1 from "../../assets/images/users/girl5.jpg";
import girl2 from "../../assets/images/users/girl6.jpg";
import boy1 from "../../assets/images/users/boy5.jpg";
import boy2 from "../../assets/images/users/boy4.jpg";
import boy3 from "../../assets/images/users/boy3.jpg";

import CustomButton from './../../components/customButton/customButton';
import UsersTableRow from '../../components/usersTableRow/usersTableRow';
import Table from '../../components/customTable/table';

const DashboardUsers = () => {
  const [users, setUsers] = useState([
    {
      id: 1,
      userImage: girl1,
      userName: "Emma Johnson",
      phone: "+01 3214 6522",
      email: "chadengle@dummy.com",
      country: "Australia",
      listings: 15,
    },
    {
      id: 2,
      userImage: boy1,
      userName: "Noah Wilson",
      phone: "+01 3214 6522",
      email: "chadengle@dummy.com",
      country: "Texas",
      listings: 15,
    },
    {
      id: 3,
      userImage: boy2,
      userName: "Liam Thompson",
      phone: "+01 3214 6522",
      email: "chadengle@dummy.com",
      country: "Spain",
      listings: 15,
    },
    {
      id: 4,
      userImage: girl2,
      userName: "Sophia Davis",
      phone: "+01 3214 6522",
      email: "chadengle@dummy.com",
      country: "Canada",
      listings: 15,
    },
    {
      id: 5,
      userImage: boy3,
      userName: "Luck Wilson",
      phone: "+01 3214 6522",
      email: "chadengle@dummy.com",
      country: "Netherlands",
      listings: 15,
    },
  ]);

  return (
    <main className={userStyle.mainContainer}>
      <PageHeader description="Airport Hotels The Right Way To Start A Short Break Holiday">User Details</PageHeader>
      <div className={userStyle.buttons}> {/* Updated to use styles */}
        <CustomButton>Add New</CustomButton>
        <CustomButton icon={<FaChevronLeft />}>Filter</CustomButton>
      </div>
      <Table className={userStyle.usersTable}>
        <Table.HeadController>
          <th>User</th>
          <th>Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Country</th>
          <th>Listings</th>
          <th></th>
          <th>View</th>
          <th>Edit</th>
          <th>Delete</th>
        </Table.HeadController>
        <Table.BodyController>
          {users.map(user => (
            <UsersTableRow
              key={user.id}
              userImage={user.userImage}
              userName={user.userName}
              phone={user.phone}
              email={user.email}
              country={user.country}
              listings={user.listings}
              viewOnClick={() => console.log(`View ${user.userName}`)} // Example handler
              editOnClick={() => console.log(`Edit ${user.userName}`)} // Example handler
              deleteOnClick={() => console.log(`Delete ${user.userName}`)} // Example handler
            />
          ))}
        </Table.BodyController>
      </Table>
    </main>
  );
};

export default DashboardUsers;

