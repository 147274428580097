import React from 'react';
import style from "./usersTableRow.module.css";
import "../../dashboardIndex.css";

import { BsEyeFill, BsPencilSquare, BsTrashFill } from "react-icons/bs";

const UsersTableRow = ({
    userImage,
    userName,
    phone,
    email,
    country,
    listings,
    viewOnClick,
    editOnClick,
    deleteOnClick
}) => {
    return (
        <tr className={style.row}>
            <td className={style.img}>
                <span style={{ backgroundImage: `url(${userImage})` }}></span>
            </td>
            <td>{userName}</td>
            <td>{phone}</td>
            <td>{email}</td>
            <td>{country}</td>
            <td><span>{listings}</span></td>
            <td></td>
            <td className="transitionAnimation" onClick={viewOnClick}><BsEyeFill /></td>
            <td className="transitionAnimation" onClick={editOnClick}><BsPencilSquare /></td>
            <td className="transitionAnimation" onClick={deleteOnClick}><BsTrashFill /></td>
        </tr>
    );
};

export default UsersTableRow;
