import React, { useState } from 'react'
import destinationStyle from "./dashboardDestinations.module.css"
import Pagination from './../../components/pagination/pagination';

import img1 from "../../../images/jordan.jpg";
import img2 from "../../../images/palestine.jfif";
import img3 from "../../../images/khalifa-dubai.jfif";
import img4 from "../../../images/BesseggenRidge.jpeg";
import img5 from "../../../images/ThailandDisneyLand.jpg";
import img6 from "../../../images/MarinaBaySandCity.jpg";
import img7 from "../../../images/NewZealand.jpg"
import DestinationCard from '../../components/destinationCard/destinationCard';

const DashboardDestinations = () => {
  const [destinations, setDestinations] = useState([
    {
      id: 1,
      image: img4,
      country: "Norway",
      content: "Besseggen Ridge",
      rating: '60%',
    },
    {
      id: 2,
      image: img5,
      country: "Thailand",
      content: "Disney Land",
      rating: '20%',
    },
    {
      id: 3,
      image: img6,
      country: "Singapore",
      content: "Marina Bay Sand City",
      rating: '40%',
    },
    {
      id: 4,
      image: img7,
      country: "New Zealand",
      content: "Oxolotan City",
      rating: '80%',
    },
    {
      id: 5,
      image: img3,
      country: "Dubai",
      content: "khalifa tower",
      rating: '20%',
    },
    {
      id: 6,
      image: img2,
      country: "Palestine",
      content: "Al-Aqsa Mosque",
      rating: '100%',
    },
    {
      id: 7,
      image: img1,
      country: "Jordan",
      content: "jordan Capital",
      rating: '60%',
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = destinations.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(destinations.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <main className='mainContainer'>
      <div className={destinationStyle.packagesContainer}>
        {currentItems.map((destination) => (
          <DestinationCard
            key={destination.id}
            image={destination.image}
            country={destination.country}
            content={destination.content}
            rating={destination.rating}
          />
        ))}
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </main>
  );
}

export default DashboardDestinations