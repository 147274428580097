import React, { useState } from 'react'
import style from "./editItineraryCard.module.css"
import Loading from '../../../../components/shared/Loading/Loading';
import { IoTrash } from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import { IoCloseCircle } from "react-icons/io5";

import { GoogleMap, LoadScript, Marker, useJsApiLoader } from "@react-google-maps/api";
import Form from '../../../../components/shared/Form/Form';
import Modal from '../../../../components/shared/Modal/Modal';



const EditItineraryCard = ({ itinerary, edit }) => {
    const { days, NB } = itinerary;
    const [openAddDay, setOpenAddDay] = useState(false);

    return (
        <>
            <div className={style.itineraryCard}>
                <div className={style.map}>
                    <GoogleMapsComponent />
                </div>
                <div className={`${style.daysContainer} ${edit && style.edit}`}>
                    {edit ?
                        <div className={style.editDaysContainer}>
                            <div>
                                <button className='button-primary' onClick={() => setOpenAddDay(!openAddDay)}>Add Day</button>
                            </div>
                            <div>
                                {days.map((day) => <DaySection edit={edit} day={day} />)}
                            </div>
                        </div>
                        :
                        <div>
                            {days.map((day) => <DaySection day={day} />)}
                        </div>
                    }
                </div>
            </div>
            {openAddDay &&
                <Modal isAppear onClose={() => setOpenAddDay(false)}>
                    <AddDay />
                </Modal>
            }
        </>
    )
}

export default EditItineraryCard


const DaySection = ({ edit, day }) => {
    const { label, programDetails, meals, visits } = day
    const [editDay, setEditDay] = useState(false);

    return (
        <>
            <div className={style.daySectionContainer}>
                <div>
                    <h3>{label}</h3>
                    <ProgramDetailsCard programDetails={programDetails} />
                    <h4>Meals:<span>{meals}</span></h4>
                    <h4>Meals:<span>{visits}</span></h4>
                </div>
                {edit ? <div><div onClick={() => setEditDay(true)}><MdOutlineEdit /></div><div><IoTrash /></div></div> : <span></span>}
            </div>
            {editDay &&
                <Modal isAppear onClose={() => setEditDay(false)}>
                    <AddDay edit={day} />
                </Modal>
            }
        </>
    )
}


const ProgramDetailsCard = ({ programDetails }) => {
    const { details, summary } = programDetails;
    return (
        <div className={style.programDetailsContainer}>
            {details.map((item) => <p>- {item}</p>)}
            <h4>{summary}</h4>
        </div>
    )
}


const GoogleMapsComponent = ({ }) => {
    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: -3.745,
        lng: -38.523
    };


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDz45PchlXICnYE-3Q9Kz5vWHrR0nzzWkw"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker position={center} />
        </GoogleMap>
    ) : <div className={style.loading}><Loading /></div>
};



const AddDay = ({ edit }) => {
    const [newDay, setNewDay] = useState(edit ? { label: edit.label, details: edit.programDetails.details, summary: edit.programDetails.summary, meals: edit.meals, visits: edit.visits } : { label: '', details: [], summary: '', meals: '', visits: '' });
    
    console.log(newDay)
    return (
        <div style={{ height: "70vh", width: '50vw', padding: "0 30px", overflowY: "auto" }}>
            <Form>
                <Form.TextController placeholder="Label" value={newDay.label} onChange={(e) => setNewDay({ ...newDay, label: e.target.value })} />
                <Form.AddListController placeholder="Enter details to add" value={newDay.details} onChange={(updatedDetails) => setNewDay({ ...newDay, details: updatedDetails })} />
                <Form.TextController placeholder="Summary" value={newDay.summary} onChange={(e) => setNewDay({ ...newDay, summary: e.target.value })} />
                <Form.TextController placeholder="Meals" value={newDay.meals} onChange={(e) => setNewDay({ ...newDay, meals: e.target.value })} />
                <Form.TextController placeholder="Visits" value={newDay.visits} onChange={(e) => setNewDay({ ...newDay, visits: e.target.value })} />
                <Form.ButtonController type='submit'>Add Day</Form.ButtonController>
            </Form>
        </div>
    )
}