import React, { useEffect, useRef, useState } from 'react'
import { RiImageAddFill } from "react-icons/ri";
import { IoCloseCircle } from "react-icons/io5";


const EditTourImages = ({
    images,
    register,
    registername,
    errors,
}) => {
    const inputRef = useRef();

    const handleFileChange = (e) => {
        const files = e.target.files;

    };

    // useEffect(() => {
    //     register(registername, {
    //         require: "Image file is required",
    //     });
    // }, [register, registername]);

    return (
        <>
            {images.map((image) => <span><IoCloseCircle title='Remove Image' /><img src={image} alt='' /></span>)}
            <input
                type="file"
                onChange={handleFileChange}
                hidden
                ref={inputRef}
                accept="image/png, image/jpeg, image/jpg, image/gif"
            />
            <button type="button" onClick={() => inputRef.current.click()}>
                <RiImageAddFill />
            </button>
        </>
    )
}

export default EditTourImages