import React, { useState } from 'react'
import { PieChart, BarChart, LineChart } from "@mui/x-charts"
import "../../dashboardIndex.css"
import home from "./dashboard.module.css"

import { BsEye } from "react-icons/bs";
import { BsCurrencyDollar } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";
import { BsQuestionLg } from "react-icons/bs";
import { BsCalendar3 } from "react-icons/bs";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { BsClockHistory } from "react-icons/bs";

import girl1 from "../../assets/images/users/girl1.jpg"
import boy1 from "../../assets/images/users/boy1.jpg"
import boy2 from "../../assets/images/users/boy2.jpg"
import girl2 from "../../assets/images/users/girl2.jpg"
import boy3 from "../../assets/images/users/boy3.jpg"
import boy4 from "../../assets/images/users/boy4.jpg"
import CustomParagraph from '../../components/customParagraph/customParagraph';
import Table from '../../components/customTable/table';


const DashboardMain = () => {
  const [recentBookingUsers, setRecentBookingUsers] = useState([
    { id: 1, img: girl1, name: 'Emma Johnson', date: '20 Aug', location: 'Amsterdam, Netherlands', count: 15 },
    { id: 2, img: boy1, name: 'Noah Wilson', date: '10 Aug', location: 'Austin, Texas', count: 15 },
    { id: 3, img: boy2, name: 'Liam Thompson', date: '1 Sep', location: 'Barcelona, Spain', count: 15 },
    { id: 4, img: girl2, name: 'Sophia Davis', date: '25 Aug', location: 'Vancouver, Canada', count: 15 },
    { id: 5, img: boy3, name: 'Noah Wilson', date: '10 Aug', location: 'Austin, Texas', count: 15 },
    { id: 6, img: boy4, name: 'Liam Thompson', date: '1 Sep', location: 'Barcelona, Spain', count: 15 },
    { id: 7, img: girl1, name: 'Emma Johnson', date: '20 Aug', location: 'Amsterdam, Netherlands', count: 15 },
    { id: 8, img: boy1, name: 'Noah Wilson', date: '10 Aug', location: 'Austin, Texas', count: 15 },
    { id: 9, img: boy2, name: 'Liam Thompson', date: '1 Sep', location: 'Barcelona, Spain', count: 15 },
    { id: 10, img: girl2, name: 'Sophia Davis', date: '25 Aug', location: 'Vancouver, Canada', count: 15 },
    { id: 11, img: boy3, name: 'Noah Wilson', date: '10 Aug', location: 'Austin, Texas', count: 15 },
    { id: 12, img: boy4, name: 'Liam Thompson', date: '1 Sep', location: 'Barcelona, Spain', count: 15 },
  ]);

  const [reportsData, setReportsData] = useState([
    { id: 2356, name: 'Paradise Retreat', price: '6,200.0' },
    { id: 7425, name: 'Tropical Oasis', price: '6,300.0' },
    { id: 5126, name: 'Adventure Haven', price: '4,200.0' },
    { id: 3269, name: 'Dreamy Escapade', price: '7,100.0' },
    { id: 4589, name: 'Paradise Retreat', price: '6,200.0' },
    { id: 6376, name: 'Tropical Oasis', price: '6,300.0' },
    { id: 8336, name: 'Adventure Haven', price: '4,200.0' },
    { id: 1346, name: 'Dreamy Escapade', price: '7,100.0' },
    { id: 2658, name: 'Paradise Retreat', price: '6,200.0' },
    { id: 2359, name: 'Tropical Oasis', price: '6,300.0' },
    { id: 9356, name: 'Adventure Haven', price: '4,200.0' },
    { id: 4356, name: 'Paradise Retreat', price: '6,200.0' },
    { id: 7325, name: 'Tropical Oasis', price: '6,300.0' },
    { id: 5126, name: 'Adventure Haven', price: '4,200.0' },
    { id: 3249, name: 'Dreamy Escapade', price: '7,100.0' },
    { id: 4589, name: 'Paradise Retreat', price: '6,200.0' },
    { id: 6176, name: 'Tropical Oasis', price: '6,300.0' },
    { id: 8536, name: 'Adventure Haven', price: '4,200.0' },
    { id: 1646, name: 'Dreamy Escapade', price: '7,100.0' },
    { id: 2668, name: 'Paradise Retreat', price: '6,200.0' },
    { id: 2259, name: 'Tropical Oasis', price: '6,300.0' },
    { id: 9956, name: 'Adventure Haven', price: '4,200.0' },
  ]);

  const uData = [266455, 169709, 158400, 142503, 80000];
  const xLabels = [
    'Saudi',
    'Canada',
    'Iran',
    'UAE',
    'Russia'
  ];

  return (
    <main className={home.mainContainer}>
      <section className={home.viewSection}>
        <div>
          <div className={home.icon}><BsEye /></div>
          <div>
            <p title='Today Views'>Views</p>
            <p>22,520</p>
          </div>
        </div>
        <div>
          <div className={home.icon}><BsCurrencyDollar /></div>
          <div>
            <CustomParagraph>Earnings</CustomParagraph>
            <p>16,520</p>
          </div>
        </div>
        <div>
          <div className={home.icon}><BsFillPersonFill /></div>
          <div>
            <CustomParagraph>Users</CustomParagraph>
            <p>18,520</p>
          </div>
        </div>
        <div>
          <div className={home.icon}><BsQuestionLg /></div>
          <div>
            <CustomParagraph>Enquiry</CustomParagraph>
            <p>19,520</p>
          </div>
        </div>
      </section>

      <section className={home.recentBooking}>
        <div className={home.heading}>
          <h3>Recent Booking</h3>
          <p>Airport Hotels The Right Way To Start A Short Break Holiday.</p>
        </div>
        <Table className={home.recentBookingTable}>
          <Table.HeadController>
            <th>User</th>
            <th>Name</th>
            <th>Date</th>
            <th>City</th>
            <th>Enquiry</th>
          </Table.HeadController>
          <Table.BodyController>
            {recentBookingUsers.map((item) => (
              <tr key={item.id}>
                <td className={home.img}><span style={{ backgroundImage: `url(${item.img})` }}></span></td>
                <td>{item.name}</td>
                <td>{item.date}</td>
                <td>{item.location}</td>
                <td><span>{item.count}</span></td>
              </tr>
            ))}
          </Table.BodyController>
        </Table>
      </section>
      <section className={home.reportingSection}>
        <h3>Reports</h3>
        <span>
          <span>
            <BsCalendar3 />
            <p>12 August 2024</p>
          </span>
          <span>
            <BsCurrencyDollar />
            <p>1250000</p>
          </span>
        </span>
        <Table className={home.reportingTable}>
          <Table.HeadController>
            <th>ID</th>
            <th>Name</th>
            <th>Amount</th>
          </Table.HeadController>
          <Table.BodyController>
            {reportsData.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.price}</td>
              </tr>
            ))}
          </Table.BodyController>
        </Table>
      </section>
      <section className={home.recentActivitiesSection}>
        <h3>Recent Activities</h3>
        <div>
          <span><BsFillLightningChargeFill /></span>
          <div>
            <span>
              <BsClockHistory />
              <p>5 mins ago</p>
            </span>
            <CustomParagraph>Jane has sent a request for access.</CustomParagraph>
          </div>

        </div>
        <div>
          <span><BsFillLightningChargeFill /></span>
          <div>
            <span>
              <BsClockHistory />
              <p>10 mins ago</p>
            </span>
            <CustomParagraph>Kathy Brown left a review on Hotel.</CustomParagraph>
          </div>

        </div>
        <div>
          <span><BsFillLightningChargeFill /></span>
          <div>
            <span>
              <BsClockHistory />
              <p>23 mins ago</p>
            </span>
            <CustomParagraph>Williams left a review on Hotel.</CustomParagraph>
          </div>

        </div>
        <div>
          <span><BsFillLightningChargeFill /></span>
          <div>
            <span>
              <BsClockHistory />
              <p>30 mins ago</p>
            </span>
            <CustomParagraph>Williams has just joined Project X.</CustomParagraph>
          </div>

        </div>
      </section>
      <section className={home.chartsSection}>
        <div>
          <h4>Site Traffic</h4>
          <span>
            <div className={`${home.lineChart} chart`}>
              <LineChart
                width={320}
                height={300}
                series={[
                  { data: uData, color: ['#d51c29'] },
                ]}
                xAxis={[{ scaleType: 'point', data: xLabels }]}
              />
            </div>
          </span>
        </div>
        <div>
          <h4>Bar Chart</h4>
          <span>
            <div className={`${home.barchart} chart`}>
              <BarChart
                width={340}
                height={300}
                series={[
                  { data: uData, id: 'uvId', color: ['#d51c29'] },
                ]}
                xAxis={[{ data: xLabels, scaleType: 'band' }]}
              >
              </BarChart>
            </div>
          </span>
        </div>
        <div>
          <h4>Search Engine</h4>
          <span>
            <div className={home.piechart}>
              <PieChart
                series={[
                  {
                    data: [
                      { id: 0, value: 79.45, label: "Google", color: '#d51c29' },
                      { id: 1, value: 7.31, label: "Bing", color: '#e0dbd8' },
                      { id: 2, value: 7.06, label: "Baidu", color: '##383838' },
                      { id: 3, value: 4.91, label: "Yahoo", color: '#8f7a6e' },
                      { id: 4, value: 1.26, label: "Others", color: '#707070' },
                    ],
                  },
                ]}
                width={300}
                height={200}
              />
            </div>
          </span>
        </div>
      </section>
    </main>
  )
}

export default DashboardMain