import React from 'react'
import style from "./Loading.module.css"

const Loading = () => {
    return (
        <div className={style.spinner}>
            <div className={style.bubble1}></div>
            <div className={style.bubble2}></div>
        </div>
    )
}

export default Loading