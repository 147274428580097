import React from 'react'
import "../../dashboardIndex.css"
import wishlistStyle from "./wishlistCard.module.css"

import { BsPencilFill, BsTrashFill } from "react-icons/bs";

const WishlistCard = ({ children, image, price, rating, review }) => {
    return (
        <div className={wishlistStyle.card}>
            <div className={wishlistStyle.imageSection} style={{ backgroundImage: `url(${image})` }}>
                <div className={wishlistStyle.price}>{price} <sub>/ per person</sub></div>
            </div>
            <div className={wishlistStyle.contentSection}>
                <div>
                    <h4>{children}</h4>
                    <div className={wishlistStyle.ratingStart} title="Rated 5 out of 5">
                        <span style={{ width: rating }}></span>
                    </div>
                </div>
                <span>
                    <p className={wishlistStyle.review}>{review} reviews</p>
                    <div className={wishlistStyle.actionSection}>
                        <span className='transitionAnimation'><BsPencilFill /> Edit</span>
                        <span className='transitionAnimation'><BsTrashFill /> Delete</span>
                    </div>
                </span>
            </div>
        </div>
    )
}

export default WishlistCard