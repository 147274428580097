import React from "react";
import PackageWrap from "./PackageWrap";
import img7 from "../../images/img7.jpg";
import img8 from "../../images/gallery-8.jpg";
import img9 from "../../images/El-Moez-Street.jpg";
import img6 from "../../images/img6.jpg";
import img14 from "../../images/img14.jpg";
import karnak from "../../images/karnak.jpeg";
import luxorTrip from "../../images/Luxor-Trips-in-Egypt.jpg";
import camelTrip from "../../images/camel-trip.jpg";
import Saqqara from "../../images/Saqqara.jpg";
import img12 from "../../images/khan.jpg";


const PackageSection = () => {
  const packages = [
    {
      id: 1,
      image: img9,
      price: "$1,900",
      duration: "7D/6N",
      people: "5",
      location: "Egypt,Cairo",
      title: `Cairo Day tour 
Starting point : Cairo or Giza Hotel 
Ending point : Cairo or Giza Hotel 
`,
      reviews: "25 reviews",
      rating: "60%",
      description: `Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

1- Visit Egyptrian museum 
2- Visit El Moez Street
3- Visit khan khalili

Meal : lunch 
(Duration 4-5Hours)
`,
    },
    {
      id: 2,
      image: img7,
      price: "$1,230",
      duration: "5D/4N",
      people: "8",
      location: "Egypt",
      title: `Day tour from Cairo  to Luxor  
(BY Flight )
Starting point : Cairo  Hotel 
Ending point : Cairo Hotel
`,
      reviews: "17 reviews",
      rating: "100%",
      description: `Pickup from Cairo Hotel to the Airport take Domestic Flight to Luxor 

Visit Karnak Temples  
Visit Luxor Temples ( from out side)
Visit valley of the kings 
Visit Colossi of Memnon

Meal : Lunch 

Then transfer back to Luxor Airport Fly back to Cairo.

(Duration  about 10 Hours)
`,
    },
    {
      id: 3,
      image: camelTrip,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: `Day tour from Hurghada to Cairo 
(BY Flight )
Starting point : Hurghada Hotel 
Ending point : Hurghada Hotel 

`,
      reviews: "22 reviews",
      rating: "100%",
      description: `Pickup from Hurghada Hotel to the Airport take Domestic Flight to Cairo 

Visit the Egyptian Museum 
Visit the Pyramids of Giza
Meals: Lunch 

(Duration 9 Hours)

After finishing the Tour.
 Then transfer back to Cairo  Airport Fly back to Hurghada.
`,
    },
    {
      id: 4,
      image: img8,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: `Egypt`,
      title: `Cairo Day tour 
Starting point : Cairo or Giza Hotel 
Ending point : Cairo or Giza Hotel 
`,
      reviews: "22 reviews",
      rating: "100%",
      description: `Day tour to the Pyramids 
Pickup From hotel 
Visit Giza Pyramids and Sphinx.
Visit Egyptian Museum
Visit old Cairo 

Meal : Lunch

Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

 (Duration 8 Hours)
`,
    },
    {
      id: 5,
      image: img6,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: `Cairo Day tour 
Starting point : Cairo or Giza Hotel 
Ending point : Cairo or Giza Hotel `,
      reviews: "22 reviews",
      rating: "100%",
      description: `Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

Visit El Moez Street
Visit Bayt Al-Sihaymi
Visit Al Darb AL Ahmar
Visit Bab Al-Futuh
Visit Bab Zuweila
Visit Bab El Nasr
Visit Mosque – Madrasa of Sultan Barquq
Visit Qalawun Complex
Visit Al-Aqmar Mosque
Visit Aqsunqur Mosque (Blue Mosque)
Visit El Darb EL ASFAR
Visit Souq El-Selah Street

Meal :Lunch 

 (Duration 4-5Hours)
`,
    },
    {
      id: 6,
      image: luxorTrip,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: `Day tour from Hurghada to Cairo 
(BY Vehicle )
Starting point : Hurghada Hotel 
Ending point : Hurghada Hotel  `,
      reviews: "22 reviews",
      rating: "100%",
      description: `Early Morning take the private A/C from your Hotel and Drive to Cairo (500km)
When reach Cairo start your day Tour:

Visit the Egyptian Museum 
Visit the Pyramids of Giza
Meals: Lunch 

(Duration 18 Hours)

After finishing the Tour.
 Transfer back to the Hotel.

Option tours:
●Felucca ride on the Nile 
●Entrance to inside the pyramid
●Camel ride near to the pyramids of Giza
`,
    },
    {
      id: 7,
      image: Saqqara,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: `Cairo Day tour 
Starting point : Cairo or Giza Hotel 
Ending point : Cairo or Giza Hotel 
`,
      reviews: "22 reviews",
      rating: "100%",
      description: `Day tour to the Pyramids 
Pickup From hotel 
Visit Giza Pyramids and Sphinx.
Visit Saqqara Step Pyramid and The Necropolis.
Visit Memphis open air Museum.

Meal : Lunch 

Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

 (Duration 8 Hours)`,
    },
    {
      id: 8,
      image: img12,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: `Cairo Day tour 
Starting point : Cairo or Giza Hotel 
Ending point : Cairo or Giza Hotel 
`,
      reviews: "22 reviews",
      rating: "100%",
      description: `Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

Visit Egyptian museum 
Visit Citadel of Saladin 
Visit khan khalili

Meal : lunch 

 (Duration 7 Hours)
`,
    },
    {
      id: 9,
      image: img14,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: `Cairo Day tour 
Starting point : Cairo or Giza Hotel 
Ending point : Cairo or Giza Hotel `,
      reviews: "22 reviews",
      rating: "100%",
      description: `Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:

Visit The Grand Egyptian Museum.
Visit  the pyramids of Giza

Meal : Lunch

After finishing the Tour proceed to a Local Restaurant to enjoy your Lunch Meal.
 Transfer back to the Hotel.

`,
    },
    {
      id: 10,
      image: karnak,
      price: "$2,000",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: `Day tour from Hurghada to Luxor  
(BY Vehicle )
Starting point : Hurghada Hotel 
Ending point : Hurghada point  `,
      reviews: "22 reviews",
      rating: "100%",
      description: `Early Morning take the private A/C from your Hotel and Drive to Luxor (310km)
Visit Karnak Temples  
Visit Luxor Temples ( from outside)
Visit valley of the kings 
Visit Colossi of Memnon

Meal : Lunch 

Then transfer back to Hurghada Hotel

(Duration  about 14 Hours)

`,
    },

    // Add other package details here...
  ];

  return (
    <div className="package-section">
      <div className="container">
        <div className="package-inner packages">
          <div className="row">
            {packages.map((pkg) => (
              <div className="col-lg-4 col-md-6" key={pkg.id}>
                <PackageWrap {...pkg} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageSection;
