import React from 'react'
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from "@react-google-maps/api";
import style from "./ItineraryCard.module.css"
import Loading from '../../shared/Loading/Loading';

const ItineraryCard = ({ itinerary }) => {
    const { days, NB } = itinerary;
    return (
        <div className={style.itineraryCard}>
            <div className={style.map}>
                <GoogleMapsComponent />
            </div>
            <div className={style.daysContainer}>
                <div>
                    {days.map((day) => <DaySection day={day} />)}
                </div>
            </div>
        </div>
    )
}

export default ItineraryCard


const DaySection = ({ day }) => {
    const { label, programDetails, meals, visits } = day
    return (
        <div className={style.daySectionContainer}>
            <h3>{label}</h3>
            <ProgramDetailsCard programDetails={programDetails} />
            <h4>Meals:<span>{meals}</span></h4>
            <h4>Meals:<span>{visits}</span></h4>
        </div>
    )
}


const ProgramDetailsCard = ({ programDetails }) => {
    const { details, summary } = programDetails;
    return (
        <div className={style.programDetailsContainer}>
            {details.map((item) => <p>- {item}</p>)}
            <h4>{summary}</h4>
        </div>
    )
}


const GoogleMapsComponent = () => {
    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: -3.745,
        lng: -38.523
    };


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDz45PchlXICnYE-3Q9Kz5vWHrR0nzzWkw"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker position={center} />
        </GoogleMap>
    ) : <div className={style.loading}><Loading /></div>
};