import React from 'react'
import style from "./Form.module.css"
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";
import { TbMoodEmpty } from "react-icons/tb";

import { useState } from 'react';
import EmptyNoItems from '../../../Admin/components/emptyNoItems/emptyNoItems';


const Form = ({ title, className, children, onSubmit }) => {

    return (
        <div className={`${style.container} ${className}`}>
            <h1>
                {title}
            </h1>
            <form onSubmit={onSubmit}>{children}</form>
        </div>
    );
};



const TextController = ({ placeholder, value, onChange, options, selectPlaceholder }) => {
    return (
        <>
            <div className={style.inputContainer}>
                <div className={options && style.inputWithOptions}>
                    {options && <SelectController options={options} placeholder={selectPlaceholder} name="name-title" />}
                    <input
                        type="text"
                        value={value}
                        placeholder={placeholder}
                        onChange={onChange}
                    />
                </div>
            </div>
        </>
    );
};

const SelectController = ({ options, name, placeholder, value, onChange }) => {
    return (
        <div className={style.inputContainer}>
            <select name={name} value={value} onChange={onChange}>
                <option value="" disabled selected>{placeholder}</option>
                {options.map((option, index) => <option key={index} value={option}>{option}</option>)}
            </select>
        </div>
    );
};


const AddListController = ({ placeholder, value, onChange }) => {
    const [inputValue, setInputValue] = useState('');

    const handleAddItem = () => {
        if (inputValue.trim()) {
            onChange([inputValue, ...value]);
            setInputValue('');
        }
    };

    const handleDeleteItem = (indexToDelete) => {
        const updatedList = value.filter((_, index) => index !== indexToDelete);
        onChange(updatedList);
    };

    return (
        <>
            <div className={style.inputContainer}>
                <div className={style.listContainer}>
                    <div>
                        <input type="text" placeholder={placeholder} value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                        <button type='button' onClick={handleAddItem} className='button-primary'>Add</button>
                    </div>
                    {value.length ?
                        <ul>
                            {value.map((item, index) => (
                                <li key={index}>{item}<IoCloseCircle onClick={() => handleDeleteItem(index)} /></li>
                            ))}
                        </ul>
                        :
                        <EmptyNoItems />
                    }
                </div>
            </div>
        </>
    );
};


const DateTimeController = ({ label }) => {
    return (
        <div className={style.inputContainer}>
            <div>
                <label htmlFor={label}>{label}</label>
                <input className="input-date-picker" type="date" name="s" id={label} />
            </div>
        </div>
    );
};


const CounterController = ({ label, helperText, initialValue, minValue, maxValue }) => {
    const [counter, setCounter] = useState(initialValue);

    const increase = () => {
        setCounter(counter + 1);
    };

    const decrease = () => {
        if (counter > 0) {
            setCounter(counter - 1);
        }
    };

    return (
        <div className={style.inputContainer}>
            <div className={style.counterContainer}>
                <div>
                    <p>{label}</p>
                    <p>{helperText}</p>
                </div>
                <div className={style.controls}>
                    <button type='button' className="decrease" onClick={decrease} disabled={counter === minValue} style={{ cursor: counter === minValue && "not-allowed" }}>
                        <FaMinus />
                    </button>
                    <div className="counter">{counter}</div>
                    <button type='button' className="increase" onClick={increase} disabled={counter === maxValue} style={{ cursor: counter === maxValue && "not-allowed" }}>
                        <FaPlus />
                    </button>
                </div>
            </div>
        </div>
    );
};


const TextareaController = ({ placeholder, value, onChange }) => {
    return (
        <div className={style.inputContainer}>
            <div>
                <textarea placeholder={placeholder} value={value} onChange={onChange} name="" id="" />
            </div>
        </div>
    );
};

const ButtonController = ({ children, type = "button" }) => {
    return (
        <div className={style.inputContainer}>
            <button type={type}>{children}</button>
        </div>
    );
};


Form.TextController = TextController;
Form.SelectController = SelectController;
Form.AddListController = AddListController;
Form.DateTimeController = DateTimeController;
Form.CounterController = CounterController;
Form.TextareaController = TextareaController;
Form.ButtonController = ButtonController;
export default Form;
