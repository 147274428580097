import React from 'react'
import style from "./IncludeExclude.module.css"

const includedExcludedArr = {
    included: [
        "Meet and assist service upon arrival & departure",
        "Assistance of our personal during your stay and excursions",
        "All transfers by a modern air-conditioned deluxe vehicle",
        "Accommodation on board 5 star Dahabiya on full board basis",
        "Tea and Coffee during meals on board your Dahabiya",
        "Free Soft Drinks at Minibar",
        "All Dahabiya excursions as mentioned in the itinerary",
        "Entrance fees to all sights between Luxor and Aswan",
        "Egyptologist guide during your excursions",
        "All service charges and taxes",
    ],
    excluded: [
        "Any extras not mentioned in the itinerary",
        "Tipping Kitty",
    ],
    NB: [
        "Itineraries may be changed without prior notice due to navigational circumstances, the change will only affect the sequence of the visits, but not in visits themselves.",
        "The Nile Dahabiya departure is dependent on booking at least 2 double cabins to guarantee sailing for 4 nights and 3 cabins for 3 nights Dahabiya Cruise.",
        "The Nile Dahabiya departure is dependent on booking at least 1 double suite to guarantee sailing for 7 nights Dahabiya Cruise."
    ]
}

const IncludeExclude = ({ edit }) => {
    return (
        edit ?
            <>
                <div className={style.includeExclude}>

                    <div className={style.include}>
                        <h3>Included</h3>
                        <ul>
                            {
                                (includedExcludedArr.included).map((item) => <li>{item}</li>)
                            }
                        </ul>
                        <div className={style.addInList}>
                            <input type="text" />
                            <button type='button' className='button-secondary'>Add</button>
                        </div>
                    </div>
                    <div className={style.exclude}>
                        <h3>Exclude</h3>
                        <ul>
                            {
                                (includedExcludedArr.excluded).map((item) => <li>{item}</li>)
                            }
                        </ul>
                        <div className={style.addInList}>
                            <input type="text" />
                            <button type='button' className='button-secondary'>Add</button>
                        </div>
                    </div>
                </div>
                <div className={style.nbSection}>
                    <h3>N.B.:</h3>
                    <ul>
                        {
                            (includedExcludedArr.NB).map((item) => <li>{item}</li>)
                        }
                    </ul>
                    <div className={style.addInList}>
                        <input type="text" />
                        <button type='button' className='button-primary'>Add</button>
                    </div>
                </div>
            </>
            :
            <>
                <div className={style.includeExclude}>

                    <div className={style.include}>
                        <h3>Included</h3>
                        <ul>
                            {
                                (includedExcludedArr.included).map((item) => <li>{item}</li>)
                            }
                        </ul>
                    </div>
                    <div className={style.exclude}>
                        <h3>Exclude</h3>
                        <ul>
                            {
                                (includedExcludedArr.excluded).map((item) => <li>{item}</li>)
                            }
                        </ul>
                    </div>
                </div>
                <div className={style.nbSection}>
                    <h3>N.B.:</h3>
                    <ul>
                        {
                            (includedExcludedArr.NB).map((item) => <li>{item}</li>)
                        }
                    </ul>
                </div>
            </>
    )
}

export default IncludeExclude